import packageJson from '../../package.json'

export const systemConfig = { version: packageJson.version };
export const showLog = true;
export const serverTimeout = 180000; // 3mins
export const fontSizeConfig = [
  { name: "A", value: "s", class: "" },
  { name: "A", value: "m", class: "medium" },
  { name: "A", value: "l", class: "large" },
];
export const footerText = `Copyright © 2021-2023 Surpass Solution (Hong Kong) Limited. All rights reserved. ${systemConfig.version}`;
export const lang = "Tc"; //"En"

// global configuration
export const sizeInputMaxLength = 5;

export const disabledUploadImg = false;

export const hideStockLocationItemStatus = ["SOLD"];

export const defaultBranchNum = "0001";

export const defaultStockLocation = "G2-5";

export const modalWidth = "800px";

export const alertBatchPrintCount = 20;

export const batchPrintDelay = 2000;

export const getItemImgSrc = (itemNum) => { 
  return `https://www.luxholichk.com/store/item_photo/${itemNum}`; 
}

export const stemsUserCookieName = "sssso_users";
