import React, { useEffect } from 'react'
import tickProductionInfoStyles from '../components/tickProductInfo/tickProductInfo.module.scss';
import TickConsignorInfoStyles from '../components/tickConsignorInfo/tickConsignorInfo.module.scss';
import sampleImg from "../assets/img/sample1.png";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getItemImgSrc, lang } from '../config/systemConfig';
import { tickLog } from '../utils/logger';
import dayjs from 'dayjs';
import { STATUS } from '../utils/statusEnum';

const oneCols = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 };
const twoCols = { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 };
const threeCols = { xs: 8, sm: 8, md: 8, lg: 8, xl: 8 };
const fourCols = { xs: 6, sm: 6, md: 6, lg: 6, xl: 6 };

const useTabsContentFields = () => {
  const tickTableState = useSelector((state) => state.tickTable);
  const itemsDrawerProductDetails = useSelector((state) => state.tickTable.itemsDrawerProductDetails);
  const itemsDrawerConsignorDetails = useSelector(state => state.tickTable.itemsDrawerConsignorDetails);
  const { i18n } = useTranslation();

  const lang = i18n.language === 'tc_hk' ? "Tc" : "En"; 



  useEffect(() => {
    tickLog("useTabsContentFields", itemsDrawerProductDetails)
  }, [])

  const { t } = useTranslation();

  // const transactionEnum = {                // for enum
  //   1: `t_transaction_consignment_${lang}`,
  //   2: `t_transaction_direct_${lang}`,
  //   3: `t_transaction_buy_in_${lang}`,
  //   "-": "-"
  // }

  const getProductSize = () => {
    const { width, height, length } = itemsDrawerProductDetails;
    let size;
    if (width) {
      size = `${width}`
    }
    if (height) {
      if (width) {
        size +=` x ${height}`;
      } else {
        size = `${height}`
      }
    }
    if (length) {
      if (width || height) {
        size +=` x ${length}`;
      } else {
        size = `${length}`
      }
    }

    return size || "-"
  };

  const handleArrayDefaultValue = (arr = []) => {
    if (!Array.isArray(arr) || arr.length === 0) {
      return ["-"];
    }
    return arr?.map(item => item?.[`name${lang}`] || "-")
  }

  const productInfoFields = {
    top: [
      {
        title: t("t_productNum"),
        value: itemsDrawerProductDetails?.itemNum || "-",
        key: "itemNum",
        grid: { ...fourCols },
        titleStyles: "",
        valueStyles: "",
      },
      {
        title: t("t_transactionType"),
        value: itemsDrawerProductDetails?.transactionType?.[`name${lang}`] || "-",
        key: "transactionType",
        grid: { ...fourCols },
        titleStyles: "",
        valueStyles: "",
      },
      {
        title: t("t_date"),
        value: itemsDrawerProductDetails?.transactionDate ? dayjs(itemsDrawerProductDetails?.transactionDate).format('YYYY-MM-DD') : "-",
        key: "transactionDate",
        grid: { ...fourCols },
        titleStyles: "",
        valueStyles: "",
      },
      {
        title: itemsDrawerProductDetails.status === 'RETURNED_TO_CONSIGNEE' ? t("t_returnedDate") : t("t_soldDate"),
        value: (itemsDrawerProductDetails.status === 'RETURNED_TO_CONSIGNEE' || itemsDrawerProductDetails.status === 'SOLD') && itemsDrawerProductDetails?.soldOn ? 
                  dayjs(itemsDrawerProductDetails?.soldOn).format('YYYY-MM-DD') : "-",
        key: "onSalesPrice",
        unit: "",
        grid: { ...fourCols },
        titleStyles: "red",
        valueStyles: "red",
      },
    ],
    middle: [
      {
        img: getItemImgSrc(itemsDrawerProductDetails?.itemNum),
        alt: "item_detail_img",
        key: "img",
        grid: { ...twoCols },
        titleStyles: "",
        valueStyles: "",
      },
      {
        descriptions: [
          {
            value: itemsDrawerProductDetails?.brand?.[`name${lang}`] || "-",
            key: "brand",
            grid: { ...twoCols },
            titleStyles: "",
            valueStyles: "brand",
          },
          {
            value: itemsDrawerProductDetails?.[`itemName${lang}`] || "-",
            key: "productName",
            grid: { ...twoCols },
            titleStyles: "productName",
            valueStyles: "productName",
          },
          {
            value: itemsDrawerProductDetails?.amt || "-",
            key: "price",
            unit: "HK$",
            grid: { ...twoCols },
            titleStyles: "price",
            valueStyles: "price",
          },
          {
            other: [
              {
                title: itemsDrawerProductDetails.transactionTypeId === 2 ? t("t_directInCost") : t("t_handlingFee"),
                value: itemsDrawerProductDetails.transactionTypeId === 2 ? itemsDrawerConsignorDetails?.directInCost || "-" : itemsDrawerProductDetails?.handlingFee || "-",
                unit: "",
                // unit: "%",
                key: "handingPercentage",
                grid: { ...twoCols },
                titleStyles: "",
                valueStyles: "",
              },
              {
                title: t("t_onSalesPrice"),
                value: itemsDrawerProductDetails?.onSalesPrice || "-",
                key: "onSalesPrice",
                unit: "HK$",
                grid: { ...twoCols },
                titleStyles: "",
                valueStyles: "",
              },
              {
                title: t("t_status"),
                value: t(STATUS[itemsDrawerProductDetails.status]?.label || "-"),
                unit: "",
                // unit: "%",
                key: "status",
                grid: { ...twoCols },
                titleStyles: "status",
                valueStyles: STATUS[itemsDrawerProductDetails.status]?.class || "default",
              },
              {
                title: t("t_soldTran"),
                value: (itemsDrawerProductDetails.status === 'SOLD' || itemsDrawerProductDetails.status === 'RETURNED_TO_CONSIGNEE') ? itemsDrawerProductDetails.soldTran || "-" : "-",
                key: "soldTran",
                unit: "",
                grid: { ...twoCols },
                titleStyles: "saleDate",
                valueStyles: "red",
              },
            ],
          },
        ],
      },
    ],
    end: [
      {
        title: t("t_category"),
        value: itemsDrawerProductDetails?.category?.[`name${lang}`] || "-",
        key: "category",
        grid: { ...twoCols },
        titleStyles: "",
        valueStyles: "",
      },
      {
        title: t("t_model"),
        value: itemsDrawerProductDetails?.model || "-",
        key: "model",
        grid: { ...twoCols },
        titleStyles: "",
        valueStyles: "",
      },
      {
        title: t("t_rating"),
        // value: [itemsDrawerProductDetails?.[`rating${lang}`] || "-", itemsDrawerProductDetails?.ratings[0]?.[`name${lang}`] || "-" ],
        value: itemsDrawerProductDetails?.ratings?.[0]?.[`name${lang}`] || "-",
        key: "rating",
        grid: { ...twoCols },
        titleStyles: "",
        valueStyles: "",
        // type: "multi",
      },
      {
        title: t("t_size"),
        value: getProductSize(),
        key: "size",
        grid: { ...twoCols },
        titleStyles: "",
        valueStyles: "",
      },
      {
        title: t("t_year"),
        value: itemsDrawerProductDetails?.year || "-",
        key: "year",
        grid: { ...twoCols },
        titleStyles: "",
        valueStyles: "",
      },
      {
        title: t("t_color"),
        // value: itemsDrawerProductDetails?.colors?.map(item => item?.[`name${lang}`] || "-"),
        value:  handleArrayDefaultValue(itemsDrawerProductDetails?.colors),
        key: "color",
        grid: { ...twoCols },
        titleStyles: "",
        valueStyles: "multiValue",
        type: "multi",
      },
      {
        title: t("t_countryOfOrigin"),
        // value: itemsDrawerProductDetails?.origins?.map(item => item?.[`name${lang}`] || "-"),
        value:  handleArrayDefaultValue(itemsDrawerProductDetails?.origins),
        key: "origins",
        grid: { ...twoCols },
        titleStyles: "",
        valueStyles: "multiValue",
        type: "multi",
      },
      {
        title: t("t_accessories"),
        // value: itemsDrawerProductDetails?.accessories?.map(item => item?.[`name${lang}`] || "-"),
        value: handleArrayDefaultValue(itemsDrawerProductDetails?.accessories),
        key: "accessories",
        grid: { ...twoCols },
        titleStyles: "",
        valueStyles: "multiValue",
        type: "multi",
      },
      {
        title: t("t_material"),
        // value:  itemsDrawerProductDetails?.materials?.map(item => item?.[`name${lang}`] || "-"),
        value: handleArrayDefaultValue(itemsDrawerProductDetails?.materials),
        key: "materials",
        grid: { ...twoCols },
        titleStyles: "",
        valueStyles: "multiValue",
        type: "multi",
      },
      {
        title: t("t_cortex"),
        value: handleArrayDefaultValue(itemsDrawerProductDetails?.cortexs),
        key: "materials",
        grid: { ...twoCols },
        titleStyles: "",
        valueStyles: "multiValue",
        type: "multi",
      },
      {
        title: t("t_mark"),
        value: itemsDrawerProductDetails?.mark || "-",
        key: "mark",
        grid: { ...oneCols },
        titleStyles: "",
        valueStyles: "textEllipsis",
      },
    ],
  };
  const consignorInfoFields = {
    top: [
      {
        title: "t_fullName",
        value: itemsDrawerConsignorDetails?.fullName || "-",
        key: "fullName",
        grid: { ...threeCols },
        titleStyles: "",
        valueStyles: "marginRight_2",
      },
      {
        title: "t_shortName",
        value: itemsDrawerConsignorDetails?.abbreviation || "-",
        key: "abbreviation",
        grid: { ...threeCols },
        titleStyles: "",
        valueStyles: "marginRight_2",
      },
      {
        title: "t_phoneNum",
        value: itemsDrawerConsignorDetails?.phoneNum || "-",
        key: "telephone",
        grid: { ...threeCols },
        titleStyles: "",
        valueStyles: "",
      },
    ],
    middle: [
      {
        title: "t_identification_num",
        value: itemsDrawerConsignorDetails?.idNo || "-",
        key: "identificationNum",
        grid: { ...twoCols },
        titleStyles: "",
        valueStyles: "",
      }
    ],
    end: [
      {
        title: "t_address",
        value: itemsDrawerConsignorDetails?.address || "-",
        key: "address",
        grid: { ...twoCols },
        titleStyles: "",
        valueStyles: "",
      }
    ],
  };
  const getProductInfoField = () => {
    return productInfoFields;
  }

  const getConsignorInfoField = () => {
    return consignorInfoFields;
  }

  return {getProductInfoField, getConsignorInfoField}
}

export default useTabsContentFields