import { tickLog } from "./logger";

export const handleLogin = (navigate, data) => {
  localStorage.setItem(
    "USERINFO",
    JSON.stringify(data.user)
  );
  localStorage.setItem(
    "MENU",
    JSON.stringify(data.menus)
  );
  localStorage.setItem(
    "ROLE",
    JSON.stringify(data.role)
  );
  localStorage.setItem(
    "PERMISSION",
    JSON.stringify(data.permissions)
  );
  localStorage.setItem(
    "TempItems",
    JSON.stringify([])
  );
  if (data.menus.length > 0) {
    navigate(`${data.menus[0].path}`, { replace: true });
  } else {
    tickLog('No permission to access any pages');
  }
}