import React, { useEffect } from "react";
import { Tabs } from "antd";
import styles from './tickTabsContent.module.scss';
import TickProductInfo from "../tickProductInfo/TickProductInfo";
import TickConsignorInfo from "../tickConsignorInfo/TickConsignorInfo";
import { useSelector } from "react-redux";
import ConsignorApi from "../../service/consignorApi/consignorApi";
import { useDispatch } from "react-redux";
import { setItemsDrawerConsignorDetails } from "../../redux/tickTableSlice";
import { useTranslation } from "react-i18next";
import TickRemark from "../tickRemark/TickRemark";
import { tickLog } from "../../utils/logger";
import TickReceipt from "../tickReceipt/TickReceipt";


const TickTabsContent = (props) => {
  const { onChange = () => { } } = props;
  const consignorId = useSelector(state => state?.tickTable?.itemsDrawerProductDetails?.consignorId)
  const receiptNum = useSelector(state => state?.tickTable?.itemsDrawerProductDetails?.receiptNum);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const item = [
    {
      key: "1",
      label: t("t_product_info"),
      children: <TickProductInfo />,
    },
    {
    key: "2",
    label:  t("t_consigneeInformation"),
    children: <TickConsignorInfo/>,
    },
    {
      key: "3",
      label: t("t_receiptNum"),
      children: <TickReceipt />
    },
    {
    key: "4",
    label:  t("t_remark"),
    children: <TickRemark />,
    },
  ];
  
  useEffect(() => {
    if (!consignorId) return;
    listByPage();
  }, [consignorId])

  const listByPage = async () => {
    // try {
    //   const resp = await ConsignorApi.listByPage();
    //   if (resp.success) {
    //     const { data, msg } = resp;
    //     const isIncludedConsignorId = data.array.filter(item => item.id === consignorId);
    //     if (isIncludedConsignorId.length === 0 ) return;
    //     dispatch(setItemsDrawerConsignorDetails(isIncludedConsignorId[0]))
    //   }
    // } catch(e) {
    //   console.error(e)
    // } 

    try {
      const resp = await ConsignorApi.getById(consignorId);
      if (resp.success) {
        tickLog("Consignor", resp)
        dispatch(setItemsDrawerConsignorDetails(resp.data))
      }
    } catch (e) {
      tickLog("Error", e)
    }
  }
  
  const handleItems = () => {
    return item.map((val, index) => {
      if (!consignorId && index === 1) {
        return;
      }
      if (!receiptNum && index === 2) {
        return;
      }
      return val
    })
  }



  return (
    <div className={styles.container}>
      <Tabs defaultActiveKey="1" items={handleItems()} onChange={onChange} />
    </div>
  );
};

export default TickTabsContent;
