import React, { useEffect } from 'react';
import { Spin } from 'antd';
import styles from './landing.module.scss';
import { stemsUserCookieName } from '../../config/systemConfig';
import { useNavigate } from 'react-router';
import SystemApi from '../../service/systemApi/systemApi';
import { handleLogin } from '../../utils/loginUtils';
import { getCookie } from '../../utils/commonUtils';

const Landing = () => {

  const navigate = useNavigate();

  const stemsLogin = async (token) => {
    const res = await SystemApi.stemsLogin(token);
    if (res.success) {
      handleLogin(navigate, res.data)
    } else {
      navigate("/login");
    }
  }

  useEffect(() => {
    const token = getCookie(stemsUserCookieName);
    if (token) stemsLogin(token);
    else navigate("/login");
  }, []);

  return (
    <div className={styles.landingContainer}>
      <div className={styles.content}>
        <Spin size="large" />
      </div>
    </div>
  );
};

export default Landing;