import { serverTimeout } from "../config/systemConfig";
import { TICK, endpoint } from "../config/envConfig";
import { tickLog } from "../utils/logger";

export const MAG = {
  async get(uri, params = {},config = {}) {
    let response, text;
    let url = endpoint.baseUrl + uri;
    tickLog("MAG.get url", url);
    try {
      response = await MAG.fetchWithTimeout(url, {
        method: "GET",
        headers: {
          accept: "application/json",
          ...config.headers
        },
        params
      });
      // console.log("response", response);
      text = await response.text();
      return JSON.parse(text);
    } catch (e) {
      return e;
      return {
        success: false,
        msg: "NETWORK_ERROR",
        data: e,
      };
    }
  },

  async post(uri, data) {
    let response, text;
    let url = endpoint.baseUrl + uri;
    tickLog("MAG.get url", url);
    // console.log(TICK.auth.accessToken);
    try {
      response = await MAG.fetchWithTimeout(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          // "Content-Type": "text/plain",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
          "Access-Control-Request-Headers": "Content-Type",
          "Access-Control-Request-Method": "POST",
          // Authorization: TICK.auth.accessToken,
        },
        body: JSON.stringify(data),
      });
      text = await response.text();
      return JSON.parse(text);
    } catch (e) {
      console.log("MAG POST Catch Error::::", JSON.parse(e.message));
      console.log("MAG POST Catch Endpoint::::", url);
      console.log("MAG POST Catch Request Data::::", data);
      console.log("MAG POST Catch Response Text::::", text);
      return JSON.parse(e.message);
      return {
        success: false,
        msg: "NETWORK_ERROR",
        data: [{ msg: "NETWORK_ERROR" }],
      };
    }
  },

  async postFile(uri, data) {
    let response, text;
    let url = endpoint.baseUrl + uri;
    try {
      response = await MAG.fetchWithTimeout(url, {
        method: "POST",
        header: {
          Accept: "application/json",
          "content-type": "multipart/form-data" 
        },
        body: data,
      });
      text = await response.text();
      return JSON.parse(text);
    } catch (e) {
      console.log("MAG POST Catch Error::::", JSON.parse(e.message));
      console.log("MAG POST Catch Endpoint::::", url);
      console.log("MAG POST Catch Request Data::::", data);
      console.log("MAG POST Catch Response Text::::", text);
      return JSON.parse(e.message);
    }
  },

  async postAndDownloadFile(uri, data) {
    let response, text;
    let url = endpoint.baseUrl + uri;
    try {
      response = await MAG.fetchWithTimeout(url, {
        method: "POST",
        header: {
          Accept: "application/json",
          "content-type": "multipart/form-data" 
        },
        body: data,
      });
      let contentDisposition, contentType

      response.headers.forEach(console.log);
      // OR you can do this
      for(let entry of response.headers.entries()) {
        console.log(entry);
        if (entry[0] === 'content-disposition') {
          contentDisposition = entry[1]
        }
        if (entry[0] === 'content-type') {
          contentType = entry[1]
        }
      }
      const resp = {
        data: await response.arrayBuffer(),
        contentDisposition,
        contentType
      }
      console.log(resp);

      // text = await response.arrayBuffer();
      // return JSON.parse(text);
      return resp;
    } catch (e) {
      console.log("MAG POST Catch Error::::", JSON.parse(e.message));
      console.log("MAG POST Catch Endpoint::::", url);
      console.log("MAG POST Catch Request Data::::", data);
      console.log("MAG POST Catch Response Text::::", text);
      return JSON.parse(e.message);
    }
  },

  async put(uri, data) {
    let response, text;
    let url = endpoint.baseUrl + uri;
    tickLog("MAG.get url", url);
    // console.log(TICK.auth.accessToken);
    try {
      response = await MAG.fetchWithTimeout(url, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "text/plain",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, PUT, GET, OPTIONS",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Request-Headers": "Content-Type",
          "Access-Control-Request-Method": "PUT",
          // Authorization: TICK.auth.accessToken,
        },
        body: JSON.stringify(data),
      });
      // console.log("MAG response", response);
      text = await response.text();
      return JSON.parse(text);
    } catch (e) {
      console.log("MAG POST Catch Error::::", JSON.parse(e.message));
      console.log("MAG POST Catch Endpoint::::", url);
      console.log("MAG POST Catch Request Data::::", data);
      console.log("MAG POST Catch Response Text::::", text);
      return JSON.parse(e.message);
      return {
        success: false,
        msg: "NETWORK_ERROR",
        data: [{ msg: "NETWORK_ERROR" }],
      };
    }
  },

  async customMethod(endPoint, uri, method = "GET", headers = {accept: "application/json",}) {
    let response, text;
    let url = endPoint + uri;
    tickLog("MAG.get url", url);
    try {
      response = await MAG.fetchWithTimeout(url, {
        method,
        headers
      });
      // console.log("response", response);
      text = await response.text();
      return JSON.parse(text);
    } catch (e) {
      // return e;
      return {
        success: false,
        msg: "NETWORK_ERROR",
        data: e,
      };
    }
  },

  async fetchWithTimeout(resource, options = {}) {
    const { timeout = serverTimeout } = options;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal,
    });
    // tickLog("MAG response", response);
    // clearTimeout(id);

    if (response.ok) {
      clearTimeout(id);
      return response;
    } else {
      const errorJson = await response.json();
      console.log("errorJson", errorJson);
      const error = await this.errorHandler(response, errorJson?.message);
      throw new Error(JSON.stringify(error));
    }
  },

  async errorHandler(response, message) {
    const newResponse = {
      success: false,
      msg: "",
      data: [],
    };
    switch (response.status) {
      case 400:
        break;
      case 401:
        newResponse.msg = "Unauthorized";
        localStorage.clear();
        window.location.reload();
        break;
      case 422:
        newResponse.msg = message;
        break;
    }
    return newResponse;
  },

  async download(uri) {
    let response, text;
    let url = endpoint.baseUrl + uri;
    tickLog("MAG.get url", url);
    try {
      response = await MAG.fetchWithTimeout(url, {
        method: "GET",
        headers: {
          accept: "application/json",
          // "Content-Type": "application/json",
        },
      });

      let contentDisposition, contentType

      response.headers.forEach(console.log);
      // OR you can do this
      for(let entry of response.headers.entries()) {
        console.log(entry);
        if (entry[0] === 'content-disposition') {
          contentDisposition = entry[1]
        }
        if (entry[0] === 'content-type') {
          contentType = entry[1]
        }
      }
      const resp = {
        data: await response.arrayBuffer(),
        contentDisposition,
        contentType
      }
      console.log(resp);

      // text = await response.arrayBuffer();
      // return JSON.parse(text);
      return resp;
    } catch (e) {
      return e;
      return {
        success: false,
        msg: "NETWORK_ERROR",
        data: e,
      };
    }
  },
};
