import React, { memo, useEffect, useState } from "react";
import { Avatar, Dropdown, Button, Menu, MenuProps, Space } from "antd";
import {
  UserOutlined,
  LockOutlined,
  PoweroffOutlined,
  TranslationOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import variables from "../../theme/variable.scss";
import styles from "./avatarDropDown.module.scss";
import { tickLog } from "../../utils/logger";
import { ReactComponent as DefaultAvatar } from "../../assets/img/avatar/defaultAvatar.svg";
import { useDispatch } from "react-redux";
import { reset, setIsChangePwModalOpen, setItemTableData } from "../../redux/tickTableSlice";
import { closeModal, showModal } from "../../redux/tickModalSilce";
import i18n from "../../i18n";
import LanguageApi from "../../service/systemApi/languageApi";
import SystemApi from "../../service/systemApi/systemApi";
import { stemsUserCookieName } from "../../config/systemConfig";
import { getCookie } from "../../utils/commonUtils";

const AvatarDropDown = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [language, setLanguage] = useState(i18n.language);

  const dispatch = useDispatch();

  useEffect(() => {
    setUsername(JSON.parse(localStorage.getItem("USERINFO")).username);
  }, []);

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language])

  const onActionClick = (e) => {
    tickLog("event", typeof e.key);
    if (e.key === "1") {
      tickLog("e.key", e.key);
    } else if (e.key === "2") {
      // tickLog("e.key", e.key);
      dispatch(setIsChangePwModalOpen(true))
    } else if (e.key === "3") { 
      const stemsToken = getCookie(stemsUserCookieName);
      // If stems token exists, logout from stems first
      if (stemsToken) {
        SystemApi.stemsLogout(stemsToken).then((res) => {
          if (res.success) {
            localStorage.clear();
            console.log("res.data", res.data);
            window.location.href = res.data;
          } else {
            dispatch(
              showModal({
                key: "warning",
                header: "This is a warning message",
                msg: "Fail to logout from backstage. Redirect to login page.",
                // showCloseBtn: true,
                callback: () => {
                  SystemApi.logout().then((res) => {
                    if (res.success) {
                      navigate("/login", { replace: true });
                      window.location.reload();
                    }
                  })
                },
              })
            )
          }
        })
      } else {
        SystemApi.logout().then((res) => {
          if (res.success) {
            navigate("/login", { replace: true });
            window.location.reload();
          } else {
            dispatch(
              showModal({
                key: "warning",
                header: "This is a warning message",
                msg: "Fail to logout",
                // showCloseBtn: true,
                callback: () => {
                  dispatch(closeModal());
                },
              }))
            tickLog(res.msg)
          }
        })
      }
    }
  };

  const changeLocale = (l) => {
    if (language !== l) {
      i18n.changeLanguage(l);
    }
    dispatch(
      setItemTableData({ totalCount: 0 })
    )
    navigate("/main")
  };

  const changeLanguage = (lang) => {
    LanguageApi.changeLanguage(lang).then((res) => {
      if (res.success) {
        tickLog("System Language", lang)
      } else {
        dispatch(
          showModal({
            key: "warning",
            header: "This is a warning message",
            msg: res.msg,
            // showCloseBtn: true,
            callback: () => {
              dispatch(closeModal());
            },
          }))
        tickLog(res.msg)
      }
    })
    setLanguage(lang);
    changeLocale(lang);
  };

  const items = [
    // {
    //   key: "1",
    //   label: <span>{t("t_personalInfo")}</span>,
    //   icon: <UserOutlined />,
    // },
    {
      key: "2",
      label: <span>{t("t_changePw")}</span>,
      icon: <LockOutlined />,
    },
    {
      key: "3",
      label: <span>{t("t_signOut")}</span>,
      icon: <PoweroffOutlined />,
    },
    {
      key: "4",
      label: 
      <>
        <Button 
          type={language === 'tc_hk' ? "default" : "dashed"} 
          style={{height: 25, padding: 0, width: 40}} 
          className={language === 'tc_hk' ? styles.languageButton : null}
          onClick={() => changeLanguage('tc_hk')}
        >
        中
        </Button>
        <Button 
          type={language === 'en' ? "default" : "dashed"} 
          style={{height: 25, padding: 0, width: 40}} 
          className={language === 'en' ? styles.languageButton : null}
          onClick={() => changeLanguage('en')}
        >
        ENG
        </Button>
      </>,
      icon: <TranslationOutlined />,
    },
  ];

  return (
    <>
      <Dropdown
        menu={{
          onClick: (e) => onActionClick(e),
          items,
        }}
        placement="bottom"
        trigger={["click"]}
        overlayClassName={styles.avatarLayout}
      >
        <Space style={{ marginRight: "20px", cursor: "pointer", marginLeft: "20px" }}>
          <Avatar
            size="small"
            icon={<UserOutlined />}
            alt="Avatar"
            style={{ backgroundColor: variables.themeTextDisableGrey }}
          />
          <span className={styles.text}>{username}</span>
        </Space>
      </Dropdown>
    </>
  );
};

export default memo(AvatarDropDown);
