import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ConsignorApi from '../service/consignorApi/consignorApi';
import ConsignmentContractApi from '../service/consignmentContractApi/consignmentContractApi';

const useTitleBarConsignment = (action) => {
  const [createDisabled, setCreateDisabled] = useState(true);
  const [modalTitle, setModalTitle] = useState();

  const tickTableState = useSelector((state) => state.tickTable);
  const consignorEdited = useSelector((state) => state.tickTable.consignment.consignor);


  const { t } = useTranslation();

  useEffect(() => {
    if (action === "consignor") {
      setCreateDisabled(!consignorEdited);
      setModalTitle(t("t_consignor&ItemsInformation"));
    } else if (action === "contract") {
      setCreateDisabled(!consignorEdited || tickTableState.consignment.tempItems.length <= 0)
      setModalTitle(t("t_consignmentContractInformation"));
    }
  }, [consignorEdited, tickTableState.consignment.tempItems])

  return [createDisabled, setCreateDisabled, modalTitle];
}

export default useTitleBarConsignment;
